import Vue from 'vue'
import VueI18n from 'vue-i18n'
import getBrowserLocale from '@/util/i18n/get-browser-locale'
import { supportedLocalesInclude } from '@/util/i18n/supported-locales'

Vue.use(VueI18n)

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true })
  if (localStorage.getItem('loc')) {
    return localStorage.getItem('loc')
  }
  if (supportedLocalesInclude(browserLocale)) {
    localStorage.setItem('loc', browserLocale)
    return browserLocale
  } else {
    localStorage.setItem('loc', process.env.VUE_APP_I18N_LOCALE || 'en')
    return process.env.VUE_APP_I18N_LOCALE || 'en'
  }
}

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
