<template>
  <div class="avo-blocks">
    <div class="avo-block avo-block_green">
      <div class="avo-block__text" v-html="$t('main.avo1')" />
    </div>
    <div class="avo-block avo-block_dark">
      <div class="avo-block__text" v-html="$t('main.avo2')" />
    </div>
    <div class="avo-block avo-block_brown">
      <div class="avo-block__text" v-html="$t('main.avo3')" />
      <div class="avo-block__note" v-html="$t('main.avo3_1')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvoBlocks'
}
</script>
