<template>
  <div>
    <top-block />
    <avo-blocks />
    <pref-block />
    <install-block />
    <promo-block />
  </div>
</template>

<script>
import TopBlock from '@/components/TopBlock.vue'
import AvoBlocks from '@/components/AvoBlocks.vue'
import PrefBlock from '@/components/PrefBlock.vue'
import InstallBlock from '@/components/InstallBlock.vue'
import PromoBlock from '@/components/PromoBlock.vue'

export default {
  name: 'HomeView',
  components: {
    TopBlock,
    AvoBlocks,
    PrefBlock,
    InstallBlock,
    PromoBlock
  }
}
</script>
