<template>
  <button
    :class="`switcher switcher_${locale}`"
    @click="setLocale(locale === 'ru' ? 'en' : 'ru')"
  >
    <span
      v-for="l in locales"
      :class="['switcher__btn', { active: locale === l.code }]"
      :key="l.code"
    >
      {{ l.name }}
    </span>
  </button>
</template>

<script>
import { getSupportedLocales } from '@/util/i18n/supported-locales'

export default {
  name: 'LangSwitcher',
  data() {
    return {
      locale: this.$i18n.locale,
      locales: getSupportedLocales()
    }
  },

  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      this.setLocale(newVal)
    }
  },

  methods: {
    setLocale(locale) {
      localStorage.setItem('loc', locale)
      this.$i18n.locale = locale
      this.locale = locale
    }
  }
}
</script>
