<template>
  <ul class="pref-list">
    <li v-for="(item, i) in list" class="pref-item" :key="i">
      <h2 class="pref-item__title" v-html="item.title" />
      <img
        :src="require(`../assets/img/icons/${i}.svg`)"
        class="pref-item__icon"
        alt=""
      />
      <div class="pref-item__text">{{ item.text }}</div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PrefBlock',

  computed: {
    list() {
      return this.$t('main.pref')
      }
  }
}
</script>
