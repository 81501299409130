<template>
  <header class="header">
    <router-link to="/" class="logo">
      <img src="../assets/img/logo.svg" alt="logo" />
    </router-link>

    <div :class="['header__menu', { active: mobIsActive }]">
      <nav class="header__nav">
        <router-link to="/" v-html="$t('header.tarifs')" />
        <a href="https://t.me/avoVPN_bot" target="_blank" rel="noopener noreferrer" v-html="$t('header.promo')"></a>
        <a href="https://t.me/avoVPN_bot" target="_blank" rel="noopener noreferrer" v-html="$t('header.set')"></a>
      </nav>
      <lang-switcher />
    </div>
    <div
      :class="['header__menu-backdrop', { active: mobIsActive }]"
      @click="closeMenu"
    />
    <button
      :class="['mob-menu', { active: mobIsActive }]"
      @click="toggleMenu"
    />
  </header>
</template>

<script>
import LangSwitcher from './LangSwitcher.vue'
export default {
  components: { LangSwitcher },
  name: 'PageHeader',
  data() {
    return {
      mobIsActive: false,
      body: document.querySelector('body')
    }
  },

  methods: {
    toggleMenu() {
      this.mobIsActive = !this.mobIsActive

      if (this.mobIsActive) {
        this.body.classList.add('menu-is-active')
      } else {
        this.body.classList.remove('menu-is-active')
      }
    },

    closeMenu() {
      this.body.classList.remove('menu-is-active')
      this.mobIsActive = false
    }
  },

  mounted() {
    if (window.innerWidth < 1200) {
      const links = document.querySelectorAll('.header__menu a')
      const a = [...links]
      a.map(el => {
        el.addEventListener('click', () => {
          this.closeMenu()
        })
      })
    }
  }
}
</script>
