<template>
  <footer class="footer">
    <nav class="page-wrapper">
      <ul class="footer__nav">
        <li class="footer__nav-item">
          <router-link to="/" v-html="$t('footer.instructions')" />
        </li>
        <li class="footer__nav-item">
          <router-link to="/rules" v-html="$t('footer.rules')" />
        </li>
        <li class="footer__nav-item">
          <router-link to="/policy" v-html="$t('footer.policy')" />
        </li>
        <li class="footer__nav-item">
          <router-link to="/contacts" v-html="$t('footer.contacts')" />
        </li>
        <li class="footer__nav-item">
          <img src="../assets/img/payment.png" width="140" alt="payment" />
        </li>
        <li class="footer__nav-item">
          <a
            href="https://t.me/avoCode_news"
            target="_blank"
            rel="noopener noreferrer"
          >
          <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M1.4714 8.92214C1.4714 8.92214 12.086 4.45144 15.7673 2.8772C17.1786 2.24755 21.9643 0.232513 21.9643 0.232513C21.9643 0.232513 24.1732 -0.64899 23.9891 1.49189C23.9277 2.37348 23.4369 5.45887 22.9461 8.79621C22.2097 13.5189 21.4121 18.6822 21.4121 18.6822C21.4121 18.6822 21.2894 20.1305 20.2464 20.3824C19.2033 20.6342 17.4853 19.5009 17.1786 19.2489C16.9331 19.0601 12.5768 16.2265 10.9816 14.8412C10.5521 14.4634 10.0613 13.7078 11.0429 12.8262C13.2518 10.7482 15.8901 8.16656 17.4853 6.5294C18.2216 5.77374 18.9579 4.01064 15.8901 6.15152C11.5338 9.237 7.23888 12.1335 7.23888 12.1335C7.23888 12.1335 6.25715 12.7632 4.41649 12.1965C2.57575 11.6298 0.428279 10.8742 0.428279 10.8742C0.428279 10.8742 -1.04418 9.92966 1.4714 8.92214Z" 
            fill="currentColor"
          />
          </svg> Telegram
          </a>
        </li>
        <li class="footer__nav-item"></li>
          <a
            href="https://www.youtube.com/@avoCode-video"
            target="_blank"
            rel="noopener noreferrer"
          >
          <svg width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
              fill-rule="evenodd" 
              clip-rule="evenodd" 
              d="M25.6027 3.31755C25.3049 2.19794 24.4239 1.31468 23.303 1.01197C21.2763 0.46875 13.1445 0.46875 13.1445 0.46875C13.1445 0.46875 5.01695 0.46875 2.98608 1.01197C1.86932 1.31054 0.988311 2.19379 0.68637 3.31755C0.144531 5.34945 0.144531 9.59156 0.144531 9.59156C0.144531 9.59156 0.144531 13.8337 0.68637 15.8656C0.984175 16.9852 1.86518 17.8684 2.98608 18.1711C5.01695 18.7144 13.1445 18.7144 13.1445 18.7144C13.1445 18.7144 21.2763 18.7144 23.303 18.1711C24.4197 17.8726 25.3008 16.9893 25.6027 15.8656C26.1445 13.8337 26.1445 9.59156 26.1445 9.59156C26.1445 9.59156 26.1445 5.34945 25.6027 3.31755Z" 
              fill="currentColor"
            />
            <path 
              fill-rule="evenodd" 
              clip-rule="evenodd" 
              d="M10.547 13.5019L17.3014 9.59156L10.547 5.68119V13.5019Z" 
              fill="white"
            />
          </svg> Youtube
          </a>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter'
}
</script>
